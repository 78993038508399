<template>
  <div id="app">
    <!-- Form (Incident Report) -->
    <div v-if="reportIncidentMode" class="report-incident-form">
      <h3>Report new incident</h3>
      <div class="entry">
        <span>Name</span>
        <input v-model="userIncidentData.name" type="text" />
      </div>
      <div class="entry">
        <span>Phone</span>
        <input v-model="userIncidentData.phone" type="tel" />
      </div>
      <div class="entry">
        <span>Incident</span>
        <select v-model="userIncidentData.incident">
          <!-- TODO: Add more options -->
          <option>Data missing from flow analytics in Builder</option>
          <option>Data missing from Reports</option>
          <option>Can't login to Cloud</option>
          <option>Other</option>
        </select>
      </div>
      <div class="entry">
        <span>Affected creative ID</span>
        <vue-custom-tooltip class="tooltip-icon-position" :multiline="true" label="Creative ID can be received from management view or from creative Builder / Preview URL numbers sequence e.g 1022-1885-2520-12397 - where creative ID is 12397">
          <span class="material-icons">info_outlined</span>
        </vue-custom-tooltip>
        <input v-model="userIncidentData.creative" type="text" />
      </div>
      <div class="entry">
        <span>Time window</span>
        <vue-custom-tooltip class="tooltip-icon-position" :multiline="true" label="Time window during which the issue can be witnessed in the given creative id(s)">
          <span class="material-icons">info_outlined</span>
        </vue-custom-tooltip>
        <input v-model="userIncidentData.timeWindow" type="text" />
      </div>
      <div class="entry" :style="{ 'margin-bottom': !userIncidentData.confirmation ? '121px' : '15px'}">
        <input v-model="userIncidentData.confirmation" type="checkbox" />
        <label>I understand that this system is reserved only to incidence reports where an issue have been identified, and delaying its investigation would cause harm to business.</label>
      </div>
      <div v-show="userIncidentData.confirmation" class="entry">
        <span>Description</span>
        <textarea :disabled="!userIncidentData.confirmation" v-model="userIncidentData.description" placeholder="Describe the harm delaying the investigation would cause." cols="15" rows="3"></textarea>
      </div>
      <div class="entry-buttons">
        <button @click="closeModal" class="red-button">Cancel</button>
        <button @click="saveReport" class="green-button"><span class="material-icons">add</span> Save</button>
      </div>
    </div>
    <!-- Form (Incident Details) -->
    <div v-if="incidentDetailsForm" class="add-incident-details-form">
      <h3>New incident status</h3>
      <div class="entry">
        <span>Date</span>
        <input v-model="newIncidentDataDetails.date" type="date" />
      </div>
      <div class="entry">
        <span>Time</span>
        <input v-model="newIncidentDataDetails.time" type="time" />
      </div>
      <div class="entry">
        <span>Status</span>
        <input placeholder="e.g. Work in progress" v-model="newIncidentDataDetails.status" type="text" />
      </div>
      <div class="entry">
        <span>Description</span>
        <textarea placeholder="e.g. The case is being investigated." v-model="newIncidentDataDetails.description" cols="15" rows="5"></textarea>
      </div>
      <div class="entry-buttons">
        <button @click="closeModal" class="red-button">Cancel</button>
        <button @click="saveData(false)" class="green-button"><span class="material-icons">add</span> Save</button>
      </div>
    </div>
    <!-- Form (Incident) -->
    <div v-if="incidentForm" class="add-incident-form">
      <h3>New incident</h3>
      <div class="entry">
        <span>Date</span>
        <input v-model="newIncidentData.date" type="date" />
      </div>
      <div class="entry">
        <span>Time</span>
        <input v-model="newIncidentData.time" type="time" />
      </div>
      <div class="entry">
        <span>Report ID</span>
        <select v-model="newIncidentData.reportId">
          <option :value="null">N/A</option>
          <template v-for="report in reports">
            <option :key="report.id" :value="report.id">{{ report.id }} - {{ report.problem }}</option>
          </template>
        </select>
      </div>
      <div class="entry">
        <span>Fire</span>
        <select v-model="newIncidentData.fire">
          <option :value="1">&#x1F525;</option>
          <option :value="2">&#x1F525;&#x1F525;</option>
          <option :value="3">&#x1F525;&#x1F525;&#x1F525;</option>
        </select>
      </div>
      <div class="entry">
        <span>Description</span>
        <textarea placeholder="e.g. Some users have reported issues with login page." v-model="newIncidentData.description" cols="15" rows="5"></textarea>
      </div>
      <div class="entry-buttons">
        <button @click="closeModal" class="red-button">Cancel</button>
        <button @click="saveData(true)" class="green-button"><span class="material-icons">add</span> Save</button>
      </div>
    </div>
    <div v-if="incidentForm || incidentDetailsForm || reportIncidentMode" @click="closeModal" class="blur"></div>
    <!-- Header -->
    <div class="status-card">
      <div class="status-logo">
        <svg width="100%" height="68" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.858 100">
        <defs>
          <linearGradient id="sampleGradient">
            <stop :offset="fullyOperational ? '20%' : nonOperational ? '20%' : '20%'" :stop-color="fullyOperational ? gradients.green.start : nonOperational ? gradients.red.start : gradients.yellow.stop" />
            <stop offset="100%" :stop-color="fullyOperational ? gradients.green.stop : nonOperational ? gradients.red.stop : gradients.yellow.start" />
          </linearGradient>
        </defs>
        <path :class="[fullyOperational ? 'green' : nonOperational ? 'red' : 'yellow']" d="M51.284,83.261l8.749-5.8a19.169,19.169,0,0,1-8.406-2.486L33.039,64.973a19.2,19.2,0,0,1-6.692-5.63l-.019,10.484c-.015,7.518,2.2,13.092,6.25,15.869L34.6,86.781C39.145,88.636,45.015,87.414,51.284,83.261Z" style="fill: #3bca51;" />
        <path :class="[fullyOperational ? 'green' : nonOperational ? 'red' : 'yellow']" d="M26.373,46.047a20.132,20.132,0,0,1,5.562-5.278L52.87,26.9a19.477,19.477,0,0,1,7.536-3.179l-7.343-3.952c-7.024-3.782-13.607-4.3-18.588-1.494l-1.569,1.039c-4.195,3.223-6.5,8.951-6.513,16.351Z" style="fill: #3bca51;" />
        <path d="M78.349,33.379l-7.361-3.963a19.476,19.476,0,0,1,1.5,8.041l-.048,25.116a20.089,20.089,0,0,1-1.352,7.572l8.681-5.751c6.093-4.036,9.595-9.039,10.047-14.247l0-2.237C89.351,42.269,85.306,37.124,78.349,33.379Z" fill="url(#sampleGradient)" />
        <path :class="[fullyOperational ? 'green' : nonOperational ? 'red' : 'yellow']" d="M22.328,69.819l.019-10.484.026-13.3.02-10.384a30.91,30.91,0,0,1,1.732-10.526l-9.5,6.3C4.81,37.925-.375,46.3.021,55s6.324,16.57,16.687,22.149l7.617,4.1A30.108,30.108,0,0,1,22.328,69.819Z" style="fill: #3bca51;" />
        <path d="M54.958,16.244,62.3,20.2l10.582,5.7,7.361,3.963a29.647,29.647,0,0,1,9.591,7.833l.022-11.4C89.881,14.5,85.982,5.9,78.881,2.079S62.457-.262,52.626,6.25l-9.508,6.3A29.46,29.46,0,0,1,54.958,16.244Z" fill="url(#sampleGradient)" />
        <path d="M81.974,67.729,73.293,73.48,62.242,80.8l-8.75,5.8a29.871,29.871,0,0,1-10.628,4.637l7.586,4.084c5.836,3.141,11.5,4.709,16.675,4.683a21.415,21.415,0,0,0,11-2.95c7.485-4.461,11.619-13.4,11.641-25.17L89.791,60.5A30.877,30.877,0,0,1,81.974,67.729Z" fill="url(#sampleGradient)" />
        </svg>
      </div>
      <div class="status-overall">
        <div class="status-overall-status">
          <div class="status-overall-status-text" :class="[fullyOperational ? 'green' : nonOperational ? 'red' : 'yellow']">
            <h1 v-if="fullyOperational">All systems operational</h1>
            <h1 v-if="nonOperational">Something wrong</h1>
            <h1 v-if="!fullyOperational && !nonOperational">Systems operational,</h1>
            <h1 v-if="!fullyOperational && !nonOperational">with {{ limitedOperationalAmount }} {{ limitedOperationalAmount === 'one' ? 'system' : 'systems'}} having an incident</h1>
          </div>
        </div>
        <div class="status-overall-last-fire" :class="[fullyOperational ? 'shiftGreen' : nonOperational ? 'shiftRed' : 'shiftYellow']">
          <h2>Last incident {{ lastIncidentCounter }} {{ lastIncidentCounter === 1 ? 'day' : 'days' }} ago</h2>
        </div>
      </div>
      <div v-if="!isAdmin" class="report-incident-button" @click="userReportIncident">
        <span class="report-incident-icon material-icons">report_problem</span>
        <span class="report-incident-text">Report incident</span>
      </div>
      <div class="status-per-system">
        <div class="status-system" v-for="system in systems" :key="system.name">
          <span>{{ system.name }}</span>
          <select v-if="isAdmin" v-model="system.operationalStatus">
            <option :value="1">Operational</option>
            <option :value="2">Operational with incident</option>
            <option :value="3">Not operational</option>
          </select>
          <div class="status-system-icon" :class="[system.operationalStatus === 1 ? 'green' : system.operationalStatus === 2 ? 'yellow' : 'red']">
            <svg width="100%" height="13.5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 89.858 100">
              <path d="M51.284,83.261l8.749-5.8a19.169,19.169,0,0,1-8.406-2.486L33.039,64.973a19.2,19.2,0,0,1-6.692-5.63l-.019,10.484c-.015,7.518,2.2,13.092,6.25,15.869L34.6,86.781C39.145,88.636,45.015,87.414,51.284,83.261Z" style="fill: #ffffff;" />
              <path d="M26.373,46.047a20.132,20.132,0,0,1,5.562-5.278L52.87,26.9a19.477,19.477,0,0,1,7.536-3.179l-7.343-3.952c-7.024-3.782-13.607-4.3-18.588-1.494l-1.569,1.039c-4.195,3.223-6.5,8.951-6.513,16.351Z" style="fill: #ffffff;" />
              <path d="M78.349,33.379l-7.361-3.963a19.476,19.476,0,0,1,1.5,8.041l-.048,25.116a20.089,20.089,0,0,1-1.352,7.572l8.681-5.751c6.093-4.036,9.595-9.039,10.047-14.247l0-2.237C89.351,42.269,85.306,37.124,78.349,33.379Z" style="fill: #ffffff;"/>
              <path d="M22.328,69.819l.019-10.484.026-13.3.02-10.384a30.91,30.91,0,0,1,1.732-10.526l-9.5,6.3C4.81,37.925-.375,46.3.021,55s6.324,16.57,16.687,22.149l7.617,4.1A30.108,30.108,0,0,1,22.328,69.819Z" style="fill: #ffffff;" />
              <path d="M54.958,16.244,62.3,20.2l10.582,5.7,7.361,3.963a29.647,29.647,0,0,1,9.591,7.833l.022-11.4C89.881,14.5,85.982,5.9,78.881,2.079S62.457-.262,52.626,6.25l-9.508,6.3A29.46,29.46,0,0,1,54.958,16.244Z" style="fill: #ffffff;" />
              <path d="M81.974,67.729,73.293,73.48,62.242,80.8l-8.75,5.8a29.871,29.871,0,0,1-10.628,4.637l7.586,4.084c5.836,3.141,11.5,4.709,16.675,4.683a21.415,21.415,0,0,0,11-2.95c7.485-4.461,11.619-13.4,11.641-25.17L89.791,60.5A30.877,30.877,0,0,1,81.974,67.729Z" style="fill: #ffffff;"/>
            </svg>
          </div>
        </div>
      </div>
      <!-- Current -->
      <h3 v-if="currentIncidents.length > 0" class="status-fire-heading">Current incidents
        <span v-if="isAdmin" @click="openIncidentModal(false)" class="add-incident material-icons">add</span>
        </h3>
      <div v-if="currentIncidents.length > 0" class="status-fire-list">
        <div class="status-fire" v-for="(incident, index) in currentIncidents" :key="index">
          <div class="status-fire-date">
            <span class="status-fire-time">{{ moment(incident.date).format('dddd, MMMM Do YYYY, hh:mm A Z') }}
              <span @click="openIncidentModal(incident.id)" v-if="isAdmin" class="edit-incident material-icons">edit</span>
              <span @click="removeIncident(incident.id)" v-if="isAdmin" class="remove-incident material-icons">delete</span>
              <span @click="changeIncidentStatus(incident.id, true)" v-if="isAdmin" class="fix-incident material-icons">done</span>
            </span>
            <vue-custom-tooltip :multiline="true" :label="generateFireDescription(incident.fire)"><div class="status-fire-icons-container">
              <span class="status-fire-icons" v-for="(fire, index) in incident.fire" :key="index">
                &#x1F525;
              </span>
            </div></vue-custom-tooltip>
          </div>
          <br />
          <div class="status-fire-description">
            {{ incident.description }}
          </div>
          <div v-if="isAdmin" class="status-fire-show-hide-details">
            <span @click="openIncidentDetailsModal(incident.id, false)" class="material-icons">add</span>
          </div>
          <div class="status-fire-actions">
            <div class="status-fire-action" v-for="(action, index) in incident.actions" :key="index">
              <span class="status-fire-time">{{ moment(action.time).format('MMMM Do YYYY, hh:mm A Z') }}
                <span @click="openIncidentDetailsModal(incident.id, index)" v-if="isAdmin" class="edit-incident material-icons">edit</span>
                <span @click="removeIncidentDetails(incident.id, index)" v-if="isAdmin" class="remove-incident material-icons">delete</span>
              </span>
              <div class="status-fire-action-details">
                <span class="status-fire-action-status">{{ action.status }}</span> -
                <span class="status-fire-action-description">{{ action.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Past -->
      <h3 class="status-fire-heading">
        {{ isAdmin ? 'Incidents in the past' : 'Incidents in the past 30 days'}}
        <span v-if="isAdmin" @click="openIncidentModal(false)" class="add-incident material-icons">add</span>
      </h3>
      <div class="status-fire-list">
        <div class="status-fire" v-for="(incident, incidentCounter) in fixedIncidents" :key="incidentCounter">
          <div class="status-fire-date">
            <span class="status-fire-time">{{ moment(incident.date).format('dddd, MMMM Do YYYY, hh:mm A Z') }}
              <span @click="openIncidentModal(incident.id)" v-if="isAdmin" class="edit-incident material-icons">edit</span>
              <span @click="removeIncident(incident.id)" v-if="isAdmin" class="remove-incident material-icons">delete</span>
              <span @click="changeIncidentStatus(incident.id, false)" v-if="isAdmin" class="unfix-incident material-icons">clear</span>
            </span>
            <vue-custom-tooltip :multiline="true" :label="generateFireDescription(incident.fire)"><div class="status-fire-icons-container">
              <span class="status-fire-icons" v-for="(fire, index) in incident.fire" :key="index">
                &#x1F525;
              </span>
            </div></vue-custom-tooltip>
          </div>
          <br />
          <div class="status-fire-description">
            {{ incident.description }}
          </div>
          <div v-if="!isAdmin" class="status-fire-show-hide-details">
            <span v-if="!expandContent.includes(incidentCounter)" @click="expandItem(incidentCounter)" class="material-icons">add</span>
            <span v-if="!expandContent.includes(incidentCounter)" @click="expandItem(incidentCounter)">Show details</span>
            <span v-if="expandContent.includes(incidentCounter)" @click="collapseItem(incidentCounter)" class="material-icons">remove</span>
            <span v-if="expandContent.includes(incidentCounter)" @click="collapseItem(incidentCounter)">Hide details</span>
          </div>
          <div v-else class="status-fire-show-hide-details">
            <span @click="openIncidentDetailsModal(incident.id, false)" class="material-icons">add</span>
          </div>
          <transition name="fade">
          <div class="status-fire-actions" v-show="isAdmin || expandContent.includes(incidentCounter)">
            <div class="status-fire-action" v-for="(action, index) in incident.actions" :key="index">
              <span class="status-fire-time">{{ moment(action.time).format('MMMM Do YYYY, hh:mm A Z') }}
                <span @click="openIncidentDetailsModal(incident.id, index)" v-if="isAdmin" class="edit-incident material-icons">edit</span>
                <span v-if="isAdmin" class="remove-incident material-icons">delete</span>
              </span>
              <div class="status-fire-action-details">
                <span class="status-fire-action-status">{{ action.status }}</span> -
                <span class="status-fire-action-description">{{ action.description }}</span>
              </div>
            </div>
          </div>
          </transition>
        </div>
        <div class="status-fire" v-if="fixedIncidents.length === 0">
          No incidents
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* TODO
*
*/

import moment from 'moment';
let base64 = require('base-64');

let baseURL;

if (window.location.hostname === 'localhost') {
  baseURL = `http://localhost:${window.location.port}/api/v1`;
} else {
  baseURL = `${window.location.origin}/api/v1`;
}

let headers = new Headers();
headers.set('Authorization', 'Basic ' + base64.encode(process.env.VUE_APP_API_USERNAME + ":" + process.env.VUE_APP_API_PASS));

export default {
  name: 'App',
  data() {
    return {
      gradients: {
        green: {
          start: '#3bca51',
          stop: '#00ffff'
        },
        yellow: {
          start: '#fffe00',
          stop: '#ffa435',
        },
        red: {
          start: '#ff0020',
          stop: '#ff0ea7',
        }
      },
      showModal: false,
      reports: [],
      systems: [],
      incidents: [],
      moment,
      expandContent: [],
      userIncidentData: {
        name: '',
        phone: '',
        incident: 'Data missing from flow analytics in Builder',
        creative: '',
        timeWindow: '',
        confirmation: false,
        description: '',
      },
      newIncidentData: {
        date: moment().format('YYYY[-]MM[-]DD'),
        time: moment().format('HH:mm'),
        fire: 1,
        reportId: null,
        description: '',
      },
      newIncidentDataDetails: {
        date: moment().format('YYYY[-]MM[-]DD'),
        time: moment().format('HH:mm'),
        status: '',
        description: '',
      },
      // temporary data
      isAdmin: false,
      incidentForm: false,
      incidentDetailsForm: false,
      editIncidentId: null,
      editStatusId: null,
      // end of temporary data
      reportIncidentMode: false,
    }
  },
  mounted() {
    if (window.location.pathname === '/admin') {
      this.isAdmin = true;
    } else if (window.location.pathname === '/incident') {
      this.reportIncidentMode = true;
    }
  },
  created() {
    fetch(`${baseURL}/reports`, {
        headers: headers
      })
      .then(response => response.json())
      .then(result => {
        this.reports = result.data;
      })
      .catch(() => this.errorToast('Error: Data loading failed'));

    fetch(`${baseURL}/systems`, {
        headers: headers
      })
      .then(response => response.json())
      .then(result => {
        this.systems = result.data;
      })
      .then(() => {
        this.computeFavicon();
      })
      .catch(() => this.errorToast('Error: Data loading failed'));

    fetch(`${baseURL}/incidents`, {
        headers: headers
      })
      .then(response => response.json())
      .then(result => {
       this.incidents = result.data.sort((a, b) => b.date - a.date);
      })
      .catch(() => this.errorToast('Error: Data loading failed'));
  },
  computed: {
    fullyOperational() {
      return this.systems && this.systems.every(system => system.operationalStatus === 1);
    },
    limitedOperational() {
      return this.systems && this.systems.some(system => system.operationalStatus === 2);
    },
    limitedOperationalAmount() {
      const systemsAmount = this.systems && this.systems.filter(system => system.operationalStatus === 2).length;
      switch (systemsAmount) {
        case 1: return 'one';
        case 2: return 'two';
        case 3: return 'three';
        case 4: return 'four';
        case 5: return 'five';
        default: return '';
      }
    },
    nonOperational() {
      return this.systems && this.systems.some(system => system.operationalStatus === 3);
    },
    fixedIncidents() {
      return this.incidents && this.incidents.filter(incident => this.isAdmin ? incident.fixed : incident.fixed && moment().diff(incident.date, 'days') <= 30);
    },
    currentIncidents() {
      return this.incidents && this.incidents.filter(incident => !incident.fixed);
    },
    lastIncidentCounter() {
      return this.currentIncidents.length > 0 ? 0 : this.incidents.length > 0 && this.incidents[0].actions.length > 0 ? moment().diff(this.incidents[0].actions[this.incidents[0].actions.length - 1].time, 'days'): 0
    }
  },
  methods: {
    computeFavicon() {
      if (this.fullyOperational) {
        this.changeFavicon('favicon-green.ico');
      } else if (this.limitedOperational) {
        this.changeFavicon('favicon-yellow.ico');
      } else if (this.nonOperational) {
        this.changeFavicon('favicon-red.ico');
      }
    },
    changeFavicon(icon) {
        let favicon = document.querySelector('link[rel="icon"]')
        if (favicon !== null) {
            favicon.href = icon;
        } else {
            favicon = document.createElement('link')
            favicon.rel = 'icon'
            favicon.href = icon;
            document.head.appendChild(favicon)
        }
    },
    generateFireDescription(fire) {
      if (fire === 1) {
        return 'There is a low probability for adverse effects caused by this issue';
      } else if (fire === 2) {
        return 'There is a medium probability adverse effects caused by this issue';
      } else {
        return 'There is a high probability for adverse effects caused by this issue';
      }
    },
    expandItem(idx) {
      this.expandContent.push(idx);
    },
    collapseItem(idx) {
      this.expandContent = this.expandContent.filter(item => item !== idx);
    },
    userReportIncident() {
      window.location.href = window.location.pathname !== '/incident' ? window.location.href + 'incident' : window.location.href;
    },
    openIncidentModal(id) {
      if (id !== false) {
        this.editIncidentId = id;

        const incident = this.incidents.filter(incident => incident.id === id)[0];

        this.newIncidentData.date = moment(incident.date).format('YYYY[-]MM[-]DD');
        this.newIncidentData.time = moment(incident.date).format('HH:mm');
        this.newIncidentData.fire = incident.fire;
        this.newIncidentData.reportId = this.reports.filter(report => report.id === incident.reportId).length > 0 ? incident.reportId : null;
        this.newIncidentData.description = incident.description;

      } else {
        this.newIncidentData.time = moment().format('HH:mm');
      }

      this.incidentForm = true;
    },
    openIncidentDetailsModal(incidentId, statusId) {
      if (statusId !== false) {
        this.editStatusId = statusId;

        const incident = this.incidents.filter(incident => incident.id === incidentId)[0];

        const status = incident.actions.filter((action, index) => index === statusId)[0];

        this.newIncidentDataDetails.date = moment(status.time).format('YYYY[-]MM[-]DD');
        this.newIncidentDataDetails.time = moment(status.time).format('HH:mm');
        this.newIncidentDataDetails.status = status.status;
        this.newIncidentDataDetails.description = status.description;

      } else {
        this.newIncidentDataDetails.time = moment().format('HH:mm');
      }

      this.editIncidentId = incidentId;
      this.incidentDetailsForm = true;
    },
    saveReport() {
      // Simple validation
      if (this.userIncidentData.name === '' || this.userIncidentData.phone === '' || !this.userIncidentData.confirmation) {
        this.errorToast('Form filled incorrectly.');
        return;
      }

      fetch(`${baseURL}/reports/`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify({
          name: this.userIncidentData.name,
          phone: this.userIncidentData.phone,
          creativeID: this.userIncidentData.creative,
          problem: this.userIncidentData.incident,
          incident: this.userIncidentData.description,
          timeWindow: this.userIncidentData.timeWindow,
        })
      })
      .then(resp => resp.json())
      .then(() => {
        this.successToast('Incident has been reported successfully');
      })
      .catch(() => this.errorToast('Error: Incident reporting failed'))
      .finally(() => {
        this.closeModal();
      });
    },
    saveData(incident) {
      if (incident) {
        if (this.editIncidentId) {

          fetch(`${baseURL}/incidents/${this.editIncidentId}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify({
              title: 'Incident',
              description: this.newIncidentData.description,
              reportId: this.newIncidentData.reportId,
              fire: this.newIncidentData.fire,
              date: Number(moment(`${this.newIncidentData.date} ${this.newIncidentData.time}`).format('x')),
              fixed: this.incidents.filter(incident => incident.id === this.editIncidentId)[0].fixed,
              actions: this.incidents.filter(incident => incident.id === this.editIncidentId)[0].actions,
            })
          })
          .then(resp => resp.json())
          .then(data => {
            const idx = this.incidents.map(incident => incident.id).indexOf(this.editIncidentId);
            this.$set(this.incidents, idx, data.data);

            this.successToast('Data has been saved successfully');
          })
          .catch(() => this.errorToast('Error: Data saving failed'))
          .finally(() => {
            this.closeModal();
          });

          return;
        }

        fetch(`${baseURL}/incidents`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({
            title: 'Incident',
            description: this.newIncidentData.description,
            reportId: this.newIncidentData.reportId,
            fire: this.newIncidentData.fire,
            date: Number(moment(`${this.newIncidentData.date} ${this.newIncidentData.time}`).format('x')),
            fixed: false,
            actions: [],
          })
        })
        .then(resp => resp.json())
        .then(data => {
          this.incidents.unshift(data.data);

          this.successToast('Data has been saved successfully');
        })
        .catch(() => this.errorToast('Error: Data saving failed'))
        .finally(() => {
          this.closeModal();
        });


      } else {
        if (this.editStatusId) {

          const incident = this.incidents.filter(incident => incident.id === this.editIncidentId)[0];
          incident.actions[this.editStatusId] = {
            time: Number(moment(`${this.newIncidentDataDetails.date} ${this.newIncidentDataDetails.time}`).format('x')),
            status: this.newIncidentDataDetails.status,
            description: this.newIncidentDataDetails.description,
          };

          fetch(`${baseURL}/incidents/${this.editIncidentId}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(incident)
          })
          .then(resp => resp.json())
          .then(data => {
            const idx = this.incidents.map(incident => incident.id).indexOf(this.editIncidentId);
            this.$set(this.incidents, idx, data.data);

            this.successToast('Data has been saved successfully');
          })
          .catch(() => this.errorToast('Error: Data saving failed'))
          .finally(() => {
            this.closeModal();
          });

        } else {

          const incident = this.incidents.filter(incident => incident.id === this.editIncidentId)[0];

          fetch(`${baseURL}/incidents/${this.editIncidentId}`, {
            method: 'PUT',
            headers: headers,
            body: JSON.stringify(
              {
                ...incident,
                actions: [...incident.actions, {
                  time: Number(moment(`${this.newIncidentDataDetails.date} ${this.newIncidentDataDetails.time}`).format('x')),
                  status: this.newIncidentDataDetails.status,
                  description: this.newIncidentDataDetails.description,
                }]
              }
            )
          })
          .then(resp => resp.json())
          .then(data => {
            const idx = this.incidents.map(incident => incident.id).indexOf(this.editIncidentId);

            this.$set(this.incidents, idx, data.data);

            this.successToast('Data has been saved successfully');
          })
          .catch(() => this.errorToast('Error: Data saving failed'))
          .finally(() => {
            this.closeModal();
          });

        }
      }
    },
    removeIncident(id) {
      fetch(`${baseURL}/incidents/${id}`, {
        method: 'DELETE',
        headers: headers
      })
      .then(resp => resp.json())
      .then(() => {
        const idx = this.incidents.map(incident => incident.id).indexOf(id);
        this.incidents.splice(idx, 1);
      })
      .then(() => this.successToast('Data has been deleted successfully'))
      .catch(() => this.errorToast('Error: Data removing failed'));
    },
    removeIncidentDetails(incidentId, statusId) {
      const incident = this.incidents.filter(incident => incident.id === incidentId)[0];
      incident.actions.splice(statusId, 1);

      fetch(`${baseURL}/incidents/${incidentId}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(incident)
      })
      .then(resp => resp.json())
      .then(data => {
        const idx = this.incidents.map(incident => incident.id).indexOf(incidentId);
        this.$set(this.incidents, idx, data.data);

        this.successToast('Data has been deleted successfully');
      })
      .catch(() => this.errorToast('Error: Data removing failed'))
      .finally(() => {
        this.closeModal();
      });
    },
    changeIncidentStatus(id, fixed) {
      this.incidents.filter(incident => incident.id === id)[0].fixed = fixed;

      fetch(`${baseURL}/incidents/${id}`, {
        method: 'PUT',
        headers: headers,
        body: JSON.stringify(this.incidents.filter(incident => incident.id === id)[0]),
      })
      .then(resp => resp.json())
      .then(() => this.successToast('Data has been saved successfully'))
      .catch(() => this.errorToast('Error: Data saving failed'));
    },
    closeModal() {
      this.incidentForm = false;
      this.incidentDetailsForm = false;

      this.reportIncidentMode = false;

      this.editIncidentId = null;
      this.editStatusId = null;

      this.newIncidentData = {
        date: moment().format('YYYY[-]MM[-]DD'),
        time: moment().format('HH:mm'),
        fire: 1,
        reportId: null,
        description: '',
      };

      this.newIncidentDataDetails = {
        date: moment().format('YYYY[-]MM[-]DD'),
        time: moment().format('HH:mm'),
        status: '',
        description: '',
      };
    },
    successToast(msg) {
      this.$toast.open({
        message: msg,
        type: 'success',
        position: 'top',
      });
    },
    errorToast(msg) {
      this.$toast.open({
        message: msg,
        type: 'error',
        position: 'top',
      });
    }
  },
  watch: {
    systems: {
      deep: true,
      handler(newVal, oldVal) {
        if (oldVal.length) {

          fetch(`${baseURL}/systems`, {
              method: 'POST',
              headers: headers,
              body: JSON.stringify(this.systems),
          })
          .then(() => this.$toast.open({
            message: 'Data has been saved successfully',
            type: 'success',
            position: 'top',
          }))
          .then(() => {
            this.computeFavicon();
          })
          .catch(() => this.$toast.open({
            message: 'Error: Systems data saving failed',
            type: 'error',
            position: 'top',
          }));

        }
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import './styles/style.scss';
</style>
